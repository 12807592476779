import React from 'react';
import image from './images/pagina.jpg';
import './index.css';

function App() {

    function mail() {
        window.location.href = 'mailto:emb@embsp.com.br'
    }

    return (
        <div className="page">
            <img src={image} onClick={mail} alt="Embsp"/>
        </div>
    );
}

export default App;
